@import '../Global/variables.scss';

main div.wrapper{
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

main h2{
    font-size: 4rem;
}
main h2 + p {
    font-size: 1.5rem;;
    margin-bottom: 2rem;
    border-bottom: 1px solid;
}
main p {
    font-size: 1rem;
}