@import 'variables.scss';
header{
    background-color: $main;
    color: $text-light;
    overflow: auto;
    display: flex;
    justify-content: left;
    align-items: center;
}
.logo{
    margin-left: 1rem;
    margin-bottom: 0.1rem;
}
.logo > h1,.logo > h6 {
    margin: 0;
}
.logo > h6 {
     margin-left: 4px;
}

.menu, .menu > li, .menu > li > a{
    padding-left: 0.5rem;
    transition: 0.2s;
    text-decoration: none;
    color: $text-light;
    list-style: none;
}
.menu{
    min-width: 8rem;
}
header a {
    text-decoration: none;
    color: $text-light;
}
@media (min-width: $breakpoint-mobile)
{
    .menu, .menu > li, .menu > li > a{
        display: inline;
    }
}
@media (max-width: $breakpoint-mobile)
{
    .menu {
        padding-left: 2px;
        border-left: 1px solid $text-light;
    }
}

header a:hover {
    transition: 0.2s;
    color: $text-highlight !important;
}
/* Bellow is the Scroll info */
header.sticky{
    position:absolute;
    top: 0;
    left: 0;
    width:100%;
}
header.sticky > h6{
    display: none;
}
header.sticky > h1{
    font-size: 1rem;
}
