@import 'variables.scss';
footer{
    width: 100%;
    position: absolute;
    background-color: $main;
    color: $text-light;
    overflow: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    bottom: 0;
    font-size: 0.9rem;
}
footer > h6{
    padding: 5px;
    margin: 0;
}